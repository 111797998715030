import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import ContactData from "../../content/contact/index.json";
import HeroPagesCenter from "../../components/heroes/hero-pages-center";
import {Col, Container, Row} from "react-bootstrap";
import DynamicForm from "../../components/forms/dynamic-form";

const ContactIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo
        lang={intl.locale}
        title={ContactData[intl.locale].title}
        description={ContactData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroPagesCenter
        theme={`primary`}
        content={ContactData[intl.locale]}
    />
    <div className={`contact`}>
        <Container>
            <Row>
                <Col md={8}>
                    <div className={`top-overflow-wrapper`}>
                        <div className={`contact-form`}>
                            <DynamicForm type={`info`} redirect={`/forms/thank-you/`} />
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <h2>{ContactData[intl.locale].sidebar.title}</h2>
                    <div className={`mt-4`}
                         dangerouslySetInnerHTML={{ __html: ContactData[intl.locale].sidebar.text }}></div>
                    <iframe
                      loading={`lazy`}
                      title={`contacts-map`}
                      height={275}
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBBiuPDQz3e1TRvHH4NOrTpdiDoevppOR8&q=Marconistraat+16%2C+3029+AK+Rotterdam`}
                      style={{ marginTop: `20px`, marginBottom: `20px`, border: `0` }}
                      referrerPolicy={`no-referrer-when-downgrade`}
                      width={`100%`}>
                    </iframe>
                </Col>
            </Row>
        </Container>
    </div>
  </Layout>
)

export default injectIntl(ContactIndexPage)
